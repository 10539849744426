import React from 'react';
import { Neo, NeoGrid } from '@singularsystems/neo-react';
import DashboardVM from '../DashboardVM';
import { observer } from 'mobx-react';
import StatementCriteria from '../../../Models/Portfolio/StatementCriteria';

interface IDistributionGridComponentProps {
    viewModel: DashboardVM;
}

@observer
export default class DistributionGrid extends React.Component<IDistributionGridComponentProps> {

    public render() {
        const { viewModel } = this.props;

        return (
            <div>
                <div className="download-report-btn">
                    <Neo.Button variant="info" onClick={() => viewModel.setDistributionExportCriteria()}>Download Transactions</Neo.Button>
                </div>
                <div className="grid-container">
                    <Neo.Loader task={viewModel.taskRunner}>
                        <NeoGrid.Grid items={viewModel.investorService.portfolioData?.distributions}>
                            {(item, meta) => (
                                <NeoGrid.Row>
                                    <NeoGrid.Column label="Policy Number" display={meta.policyNo} />
                                    <NeoGrid.Column label="Transaction Type" display={meta.transactionType} />
                                    <NeoGrid.Column label="Settlement Date" display={meta.settlementDate} />
                                    <NeoGrid.Column label="Instrument" display={meta.instrumentName} />
                                    <NeoGrid.Column label="Instrument Code" display={meta.instrumentCode} />
                                    <NeoGrid.Column label="Amount (R)" display={meta.amount} numProps={{ currencySymbol: "R" }} />
                                    <NeoGrid.Column label="Withholding Tax (R)" display={meta.withholdingTax} numProps={{ currencySymbol: "R" }} />
                                </NeoGrid.Row>
                            )}
                        </NeoGrid.Grid>
                    </Neo.Loader>
                    <div className='grid-footer-text'>Note: Financial transactions are limited to the last 5 years</div>
                </div>

                <Neo.Modal
                    title="Distribution Report"
                    bindModel={viewModel.meta.statementCriteria}
                    closeButton={false}
                    acceptButton={{ text: "Download Report", onClick: () => viewModel.downloadDistributionReport() }}>
                    {(distributionExportCriteria: StatementCriteria) => (
                        <div>
                            <Neo.FormGroupInline bind={distributionExportCriteria.meta.startDate} />
                            <Neo.FormGroupInline bind={distributionExportCriteria.meta.endDate} />
                        </div>
                    )}
                </Neo.Modal>
            </div>
        )
    }
}