import React from 'react';
import { observer } from 'mobx-react';
import { EnumHelper, Misc, ModalUtils, NeoModel, NumberUtils } from '@singularsystems/neo-core';
import ClickLink from '../../../App/Components/ClickLink';
import { MaintenanceDocumentType } from "../../../Models/Base/Enums/MaintenanceDocumentType";
import { Neo, NeoGrid, Views } from '@singularsystems/neo-react';
import { injectable } from 'inversify';
import DocumentHandler from '../../../Services/DocumentHandler';
import { AppService, Types } from '../../../Services/AppService';
import { ReadOnlyBankAccountDetails } from '../../Investor/Components/BankAccountDetails';
import { InvestmentType } from '../../../Models/Registration/Enums/InvestmentType';
import { PaymentType } from '../../../Models/Registration/Enums/PaymentType';
import PurchaseVM from '../../Investor/Trades/PurchaseVM';

interface ITradeConfirmationComponentProps {
    viewModel: PurchaseVM,
    tradeConfirmationVM: TradeConfirmationVM;
}

@injectable()
@NeoModel
export class TradeConfirmationVM extends Views.ViewModelBase {

    constructor(
        public taskRunner = AppService.get(Types.Neo.TaskRunner),
        private documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient),
        public otpService = AppService.get(Types.Services.OtpService),
        public notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
        public recurringTradeRequest = AppService.get(Types.ApiClients.RecurringTradeRequestApiClient),
        public navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
    ) {
        super(taskRunner);
    }

    public showTradeConfirmationModal: boolean = false;
    public showSuccessModal: boolean = false;
    public showBankDetails: boolean = false;

    public showHideConfirmationModal() {
        this.showTradeConfirmationModal = !this.showTradeConfirmationModal;
    }

    public downloadMaintenanceDocument(maintenanceDocumentType: MaintenanceDocumentType) {
        this.taskRunner.run("Fetching Document", async () => {
            var response = await this.documentApiClient.fetchPublicMaintenanceDocument(maintenanceDocumentType);

            if (response) {
                DocumentHandler.convertAndDownloadDocument(response, true);
            }
        });
    };
}

@observer
export default class TradeConfirmationComponent extends React.Component<ITradeConfirmationComponentProps> {
    private config = AppService.get(Types.Config);

    public render() {
        const { viewModel } = this.props;

        return (
            <div className='trade-confirmation'>
                {viewModel.tradeRequest.paymentMethodID === PaymentType.EFT
                    ? this.renderETFModal()
                    : this.renderOnceOffCollectionModal()}
                {this.renderBrokerBankDetails()}
                {this.renderSuccessModal()}
            </div>
        );
    }

    private beginEditBankDetails() {
        this.dismissModal();
        ModalUtils.showYesNoDismissible(
            "Cancel Purchase",
            <div>
                <p>
                    Please note that your new bank details needs to be verified before you can submit this purchase instruction.
                    Please look out for the verification email from <a href="mailto:hub@etfsa.co.za">hub@etfsa.co.za. </a>
                    Once received, please reload your purchase instruction. Do you want to continue?
                </p>
            </div>,
            () => this.props.viewModel.editBankDetails());
    }

    private dismissModal(isTradeComplete: boolean = false) {
        if (!isTradeComplete) {
            this.props.tradeConfirmationVM.showTradeConfirmationModal = false;
            this.props.viewModel.tradeRequest.hasAcceptedDebitOrderAuthority = false;
            this.props.viewModel.tradeRequest.hasAcceptedTermsAndConditions = false;
        } else {
            this.props.tradeConfirmationVM.showBankDetails = false;
            this.props.viewModel.navigateToDashboard(isTradeComplete);
        }
    }

    private onSubmit(viewModel: PurchaseVM) {
        this.dismissModal();
        viewModel.showOtp();
    }

    private renderOnceOffCollectionModal() {
        const { viewModel } = this.props;
        const investor = viewModel.accountInformation?.appInvestorData.accountInformationVM.investor;
        const investorBankDetails = investor?.thirdPartyBankDetails ?? investor?.primaryBankDetails;
        const message = {
            debitFrequency: viewModel.tradeRequest.investmentTypeID === InvestmentType.RecurringInvestment ? "per month" : "",
            amountInRands: NumberUtils.format(viewModel.tradeRequest.totalPurchaseAmount, Misc.NumberFormat.CurrencyDecimals, "R")
        };

        return (
            <Neo.Modal
                title={`${EnumHelper.getItemMetadata(InvestmentType, viewModel.tradeRequest.investmentTypeID).display} Request`}
                show={this.props.tradeConfirmationVM.showTradeConfirmationModal}
                size="lg"
                closeButton={false}
                onClose={() => this.dismissModal()}
                className="trade-confirmation"
                buttons={[{ text: "Submit", variant: "info", disabled: !(viewModel.tradeRequest.hasAcceptedDebitOrderAuthority && viewModel.tradeRequest.hasAcceptedTermsAndConditions), onClick: () => this.onSubmit(viewModel) }]}>
                <Neo.Form model={viewModel.tradeRequest} >
                    <h5>You are investing in:</h5>
                    <NeoGrid.Grid items={viewModel.tradeRequest.selectedInstruments}>
                        {(item, meta) => (
                            <NeoGrid.Row>
                                <NeoGrid.Column display={item.meta.instrument} />
                                <NeoGrid.Column display={item.meta.amount} numProps={{ format: Misc.NumberFormat.CurrencyDecimals, currencySymbol: "R" }} />
                            </NeoGrid.Row>
                        )}
                    </NeoGrid.Grid>
                    <hr />
                    <Neo.GridLayout md={1} rows={5}>
                        <p className='message'>Your account will be debited with an estimated {message.amountInRands} {message.debitFrequency}. You will be notified when your investment has been completed.</p>
                        <Neo.FormGroup bind={viewModel.tradeRequest.meta.hasAcceptedTermsAndConditions} label="I have read and understood the information application to this/these investment(s)" />
                        <Neo.FormGroup bind={viewModel.tradeRequest.meta.hasAcceptedDebitOrderAuthority} label={"I have read and accepted the "}
                            append={<label className="custom-control-append-label"><ClickLink onClick={() => this.props.tradeConfirmationVM.downloadMaintenanceDocument(MaintenanceDocumentType.DebitOrderAuthority)}>debit order authority</ClickLink></label>} />
                        <hr />
                        <h5>Debit Order Account Details</h5>
                        <ReadOnlyBankAccountDetails bankAccount={investorBankDetails!} />
                    </Neo.GridLayout>
                    <Neo.GridLayout className="mt-2" >
                        <ClickLink onClick={() => this.beginEditBankDetails()}>Edit Bank Details</ClickLink>
                    </Neo.GridLayout>
                </Neo.Form>
            </Neo.Modal>
        );
    }

    private renderBrokerBankDetails() {
        const viewModel = this.props.viewModel;

        return (
            <Neo.Modal
                title="Deposit Account Details"
                show={this.props.tradeConfirmationVM.showBankDetails}
                size="lg"
                onClose={() => this.dismissModal(true)}>
                <div className="row">
                    <div className="col-12">
                        <p>Please deposit {NumberUtils.format(viewModel.tradeRequest.totalPurchaseAmount, Misc.NumberFormat.CurrencyDecimals, "R")} into the etfSA bank account.</p>
                        <div><strong>Account name</strong>: {this.config.brokerBankDetails.accountName}</div>
                        <div><strong>Bank</strong>: {this.config.brokerBankDetails.bank}</div>
                        <div><strong>Account number</strong>: {this.config.brokerBankDetails.accountNumber}</div>
                        <div><strong>Universal branch code</strong>: {this.config.brokerBankDetails.branchCode}</div>
                        <div><strong>Account Type</strong>: {this.config.brokerBankDetails.accountType}</div>
                        <div><strong>Reference number</strong>: {viewModel.accountInformation?.investor?.idNo}</div>
                        <br />
                        <p>You will be notified when your funds have cleared and your investment has been completed.</p>
                    </div>
                </div>
            </Neo.Modal>
        );
    }

    private renderETFModal() {
        const { viewModel } = this.props;

        return (
            <Neo.Modal
                title={`${EnumHelper.getItemMetadata(InvestmentType, viewModel.tradeRequest.investmentTypeID).display} Request`}
                show={this.props.tradeConfirmationVM.showTradeConfirmationModal}
                size="lg"
                closeButton={false}
                onClose={() => this.dismissModal()}
                className="trade-confirmation"
                buttons={[{ text: "Submit", variant: "info", disabled: !viewModel.tradeRequest.hasAcceptedTermsAndConditions, onClick: () => this.onSubmit(viewModel)}]}>
                <Neo.Form model={viewModel.tradeRequest}>
                    <h5>You are investing in:</h5>
                    <NeoGrid.Grid items={viewModel.tradeRequest.selectedInstruments}>
                        {(item, meta) => (
                            <NeoGrid.Row>
                                <NeoGrid.Column display={item.meta.instrument} />
                                <NeoGrid.Column display={item.meta.amount} numProps={{ format: Misc.NumberFormat.CurrencyDecimals, currencySymbol: "R" }} />
                            </NeoGrid.Row>
                        )}
                    </NeoGrid.Grid>
                    <Neo.GridLayout md={1} rows={5}>
                        <p className='message'>After you have confirmed this trade, banking details will be provided to deposit funds</p>
                        <Neo.FormGroup bind={viewModel.tradeRequest.meta.hasAcceptedTermsAndConditions} label="I have read and understood the information applicable to this/these investment(s)" />
                    </Neo.GridLayout>
                </Neo.Form>
            </Neo.Modal>
        );
    }

    private renderSuccessModal () {
        const { viewModel } = this.props;

        return (
            <Neo.Modal
                    title={"Purchase Submitted"}
                    show={this.props.tradeConfirmationVM.showSuccessModal}
                    size="lg"
                    onClose={() => viewModel.navigateToDashboard(true)}
                    closeButton={false}
                    acceptButton={{ text: "GO TO MY DASHBOARD", onClick: () => viewModel.navigateToDashboard(true)}}>
                    <p>
                        Please note your purchase request has been submitted. Contact us at <a href="mailto:hub@etfsa.co.za">hub@etfsa.co.za</a> for any queries.
                    </p>
                </Neo.Modal>
        )
    }
}