import { injectable } from 'inversify';
import { AppService, Types } from './AppService';
import IAuthenticationService from '@singularsystems/neo-core/dist/Security/IAuthenticationService';
import { ISignInResult } from '@singularsystems/neo-core/dist/Security/SignInResult';
import AppUser from '../Models/Security/AppUser';
import LoginResult, { TokenInfo } from '../Models/Security/LoginResult';
import Login from '../Views/Security/Login';
import axios from 'axios';
import { observable } from 'mobx';

const storageKey = "user-header-token";

@injectable()
export default class HeaderTokenAuthService implements IAuthenticationService {

    constructor(
        private navigation = AppService.get(Types.Neo.Routing.NavigationHelper),
        private securityApiClient = AppService.get(Types.ApiClients.SecurityApiClient)) {

        this.refreshUserToken = this.refreshUserToken.bind(this);
    }
    
    ensureSignedIn(redirectLocal?: string | undefined): boolean {
        throw new Error('Method not implemented.');
    }
    
    private redirectPath: string = "/";

    @observable.ref
    private appUser: AppUser | null = null;

    private timerHandle: any;

    public loadUserFromToken(): Promise<AppUser | null> {
        return this.loadUserFromSession();
    }

    public loadUserFromSession(): Promise<AppUser | null> {
        const userJson = sessionStorage.getItem(storageKey);
        if (userJson) {
            const userInfo = LoginResult.fromJSObject<LoginResult>(JSON.parse(userJson));
            this.appUser = new AppUser(userInfo);
            this.onUserChanged();
            return Promise.resolve(this.appUser);
        } else {
            return Promise.resolve(null);
        }
    }

    public beginSignIn(redirectLocal?: string): Promise<any> {
        this.redirectPath = redirectLocal ?? "/";
        this.navigation.navigateToView(Login);
        return Promise.resolve();
    }

    public async endSignIn(): Promise<string> {
        
        // TODO: Check if registration is required.
        // this.navigation.navigateToView(RegisterView);
        this.navigation.navigateInternal(this.redirectPath);

        return Promise.resolve(this.redirectPath);
    }

    beginSignOut(): void {
        this.appUser = null;
        this.onUserChanged();
        this.navigation.navigateToView(Login);
    }

    public storeUser(userInfo: LoginResult) {
        this.appUser = new AppUser(userInfo);

        this.onUserChanged();
        return this.appUser;
    }

    /** Returns true if there is a user, and the token has not expired */
    public get isAuthenticated(): boolean {
        return this.appUser !== null && !this.appUser.hasExpired;
    }

    /** Returns the current user */
    public get user(): AppUser | null {
        return this.appUser;
    }

    public getUserJson() {
        return sessionStorage.getItem(storageKey)!;
    }

    private onUserChanged() {
        if (this.appUser) {
            axios.defaults.headers.common['AuthToken'] = this.appUser ? this.appUser.accessToken : undefined;
        } else {
            delete axios.defaults.headers.common['AuthToken'];
        }
        
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
        }
        if (this.appUser) {
            sessionStorage.setItem(storageKey, JSON.stringify(this.appUser.userInfo.toJSObject({ suppressTrackingState: true })));

            const expiryMs = this.appUser.userInfo.tokenInfo.expiryDate.getTime() - Date.now();
            // Refresh the token 1 minute before expiry.
            this.timerHandle = setTimeout(this.refreshUserToken, expiryMs - 60000);
        } else {
            sessionStorage.removeItem(storageKey);
        }
    }

    private async refreshUserToken() {
        try {
            const result = await this.securityApiClient.refreshTokenInfo();
            this.appUser!.userInfo.tokenInfo = TokenInfo.fromJSObject<TokenInfo>(result.data);

            this.onUserChanged();

        } catch (e) {
            console.log("Error refreshing token: " + e);
            this.appUser = null;
            this.onUserChanged();
        }
    }

    /**
     * OIDC specific methods.
     * TODO: Remove if these methods are removed from IAuthenticationService in neo.
     */

    public tryCompleteSignIn(): Promise<ISignInResult> {
        throw Error("tryCompleteSignIn not supported");
    }

    public signinSilent(): Promise<any> {
        throw Error("signinSilent not supported");
    }

    public endSignInSilent(): Promise<any> {
        throw Error("endSignInSilent not supported");
    }

    public signOutLocal(): void {
        throw Error("signOutLocal not supported");
    }

    public endSignOut(): Promise<any> {
        throw Error("endSignOut not supported");
    }
}