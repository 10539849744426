import React from "react";
import { Neo, NeoGrid, Views } from "@singularsystems/neo-react";
import ProductsVM from "./ProductsVM";
import { observer } from "mobx-react";
import Funnel from "../../../assets/img/funnel.svg";
import ProductAccordion from "../Components/ProductAccordion";
import closeIcon from "../../../assets/img/close-icon.svg";
import BreadCrumb from "../../../App/Components/BreadCrumb";
import PurchaseView from "./Purchase";
import { Misc, NumberUtils } from "@singularsystems/neo-core";
import Popover from "../../../App/Components/Popover";

@observer
export default class ProductView extends Views.ViewBase<ProductsVM> {

    constructor(props: unknown) {
        super("Products", ProductsVM, props);
    }

    public getBreadCrumbList() {
        return super.getBreadCrumbList();
    }

    private containerRef = React.createRef<HTMLDivElement>();

    render() {
        const viewModel = this.viewModel;

        return (
            <div className="product-container">
                <div className="header">
                    <BreadCrumb rootItem={{ label: "Home", link: "/" }} />
                    <div className ="product-button-container" ref={this.containerRef}>
                        <Neo.Button
                            variant="info"
                            className="mr-md-2 product-button"
                            onClick={() =>
                                window.open(`${viewModel.researchPageUrl}`, "_blank")
                            }>
                            Research Page
                        </Neo.Button>
                        <Neo.Button
                            variant="info"
                            className="product-button"
                            onMouseOver={() => { this.viewModel.showPopover = true }}
                            onClick={() => this.navigation.navigateToView(PurchaseView, { instrumentCode: "none" })}>
                            <div className="icon-btn-block">
                                <i className="fa fa-shopping-cart mr-2" />
                                <span>{NumberUtils.format(this.viewModel.totalCartAmount, Misc.NumberFormat.CurrencyDecimals, "R")}</span>
                            </div>
                        </Neo.Button>
                    </div>
                </div>

                <div className="title">Products</div>
                <div className="components-container">
                    <div className="filters">
                        <div>
                            <Neo.AutoCompleteDropDown
                                className="col-md-6"
                                placeholder="Search for your product"
                                bind={viewModel.filterCriteria.meta.instrumentID}
                                displayMember="instrumentName"
                                valueMember="instrumentID"
                                items={viewModel.instruments}
                            />
                            {viewModel.filterCriteria.isSelfDirty && (
                                <img
                                    className="clear-filters"
                                    src={closeIcon}
                                    alt={"clear filters"}
                                    onClick={() => viewModel.clearFilterCriteria()}
                                />
                            )}
                        </div>
                        <Neo.GridLayout md={4}>
                            <Neo.FormGroup
                                bind={viewModel.filterCriteria.meta.regionID}
                                select={{
                                    itemSource: viewModel.appDataCache.regions,
                                    allowNulls: true,
                                    nullText: "Choose the region",
                                    onItemSelected: () => viewModel.onItemSelect(),
                                }}
                            />
                            <Neo.FormGroup
                                bind={viewModel.filterCriteria.meta.assetClassID}
                                select={{
                                    itemSource: viewModel.appDataCache.assetClasses,
                                    allowNulls: true,
                                    nullText: "Choose the asset class",
                                    onItemSelected: () => viewModel.onItemSelect(),
                                }}
                            />
                            <Neo.FormGroup
                                bindContext={
                                    viewModel.filterCriteria.meta.instrumentProviderIDs
                                }
                                select={{
                                    itemSource: viewModel.appDataCache.providers,
                                }}
                            >
                                <Neo.AutoCompleteDropDown
                                    items={viewModel.appDataCache.providers.fetchInitial()}
                                    bindItems={
                                        viewModel.filterCriteria.meta.instrumentProviders
                                    }
                                    onItemSelected={() => viewModel.onItemSelect()}
                                    placeholder="Choose the provider"
                                />
                            </Neo.FormGroup>
                            <Neo.FormGroup
                                bind={viewModel.filterCriteria.meta.strategyID}
                                select={{
                                    itemSource: viewModel.appDataCache.strategies,
                                    allowNulls: true,
                                    nullText: "Choose the Strategy",
                                    onItemSelected: () => viewModel.onItemSelect(),
                                }}
                            />
                        </Neo.GridLayout>
                        <div className={`product-list ${viewModel.filteredList.length === 0 ? "no-results" : ""}`}>
                            {viewModel.filteredList.length > 0 ? (
                                viewModel.filteredList.map((i) => (
                                    <ProductAccordion
                                        key={i.entityIdentifier}
                                        instrument={i}
                                        viewModel={viewModel}
                                        onSelect={ic => this.navigation.navigateToView(PurchaseView, { instrumentCode: ic })}
                                    />
                                ))
                            ) : (
                                <>
                                    <img src={Funnel} alt="funnel" className="funnel" />
                                    <p>Use the filters above to find products</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {this.viewModel.showPopover && this.containerRef.current &&
                    <Popover
                        anchorElement={this.containerRef.current}
                        alignment="right"
                        yOffset={10}
                        maxElementNesting={50}
                        onHide={isInPopover => {
                            if (!isInPopover) {
                                this.viewModel.showPopover = false
                            }
                        }}>
                        {viewModel.cartDetailLookups.length > 0 ?
                            <div>
                                <NeoGrid.Grid className="popover-body" items={viewModel.cartDetailLookups} footerButtons={() =>
                                    <div className="row justify-content-between mt-3">
                                        <Neo.Button onClick={() => this.navigation.navigateToView(PurchaseView, { instrumentCode: "none" })} variant="info" className="ml-3">Purchase</Neo.Button>
                                        <Neo.Button onClick={async () => await viewModel.deleteCartItem(null)} variant="info" className="mr-3">Delete All</Neo.Button>
                                    </div>
                                } showAddButton={false}>
                                    {(item, meta) => (
                                        <NeoGrid.Row>
                                            <NeoGrid.Column display={item.meta.instrumentName} width={250} />
                                            <NeoGrid.Column display={item.meta.amount} numProps={{ format: Misc.NumberFormat.CurrencyDecimals, currencySymbol: "R" }} />
                                            <NeoGrid.ButtonColumn showDelete onClick={async () => await viewModel.deleteCartItem(item.meta.instrumentID.value)} buttonSize="sm" />
                                        </NeoGrid.Row>
                                    )}
                                </NeoGrid.Grid>
                            </div>

                            : <span className="popover-item">Your cart is empty</span>
                        }
                    </Popover>
                }
            </div >
        );
    }
}