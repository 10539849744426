import { Neo } from "@singularsystems/neo-react";
import { observer } from "mobx-react";
import React, { Component } from "react";
import Instrument from "../../../Models/Instruments/Instrument";
import ProductsVM from "../Trades/ProductsVM";
import caret from '../../../assets/img/caret.png'

interface IProductAccordionProps {
  instrument: Instrument;
  viewModel: ProductsVM;
  onSelect(instrumentCode: string): void
}

@observer
export default class ProductAccordion extends Component<IProductAccordionProps> {

  render() {
    const { instrument, viewModel, onSelect } = this.props;

    return (
      <div className="product" >
        <span className="provider-logo">
          <img
            src={viewModel.documentApiClient.getInstrumentProviderImageUrl(instrument.instrumentProviderID)}
            alt="logo"
          />
        </span>
        <span className="rating">{instrument.totalExpenseRatio}%</span>
        <span className={`instrument-code ${instrument.isExpanded ? "is-extended" : ""}`}>{instrument.instrumentCode}</span>
        <div className={"information-wrapper"}>
          <div className="title-wrapper" onClick={() => instrument.isExpanded = !instrument.isExpanded}>
            <p className="title">{instrument.instrumentName}</p>
            <img src={caret} className={instrument.isExpanded ? "invert-caret" : ""} alt="caret" />
          </div>
          <p className="description" hidden={!instrument.isExpanded}>{instrument.instrumentDescription}</p>
          <div className="extra-info-wrapper" hidden={!instrument.isExpanded}>
            <p>
              <b>Type:</b> {instrument.investableType}
            </p>
            <p>
              <b>ISIN:</b> {instrument.isin}
            </p>
            <p>
              <b>Income Treatment:</b> {instrument.incomeTreatmentDescription}
            </p>
          </div>
          <Neo.Button className="button-invest" onClick={() => onSelect(instrument.instrumentCode)}>
            Invest
          </Neo.Button>
        </div>
      </div>
    );
  }
}
