import React from 'react';
import { AppService, Types } from '../../Services/AppService';
import Sidebar from './Sidebar';
import eftsaLogo from '../../assets/img/ETFSA_On Black.png';
import { observer } from 'mobx-react';

@observer
export default class LeftPanel extends React.Component {

    private appLayout = AppService.get(Types.Services.AppLayout);

    public render() {

        const sideBarType = this.appLayout.sidebarType;

        if (sideBarType === "menu") {
            return <Sidebar />
        } else if (sideBarType === "main-logo") {
            return this.renderMainLogo();
        } else {
            return null;
        }
    }

    private renderMainLogo() {
        return (
            <div className="big-sidebar">
                <div className="big-sidebar-content">
                    <img src={eftsaLogo} alt="" style={{ width: '145px' }}/>
                    <section className="motto">
                        <p>The home of <br />
                            Exchange <br />
                            Traded Funds<sup>&reg;</sup></p>
                    </section>
                </div>
            </div>
        )
    }
}