import { Views } from "@singularsystems/neo-react";
import { AppService, Types } from "../Services/AppService";

export default abstract class ETInvestorVMBase extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public investorService = AppService.get(Types.Services.CurrentInvestorService),
        public oldSiteIntegration = AppService.get(Types.Services.OldSiteIntegration)) {
        super(taskRunner);
    }

    public get investorInfo () {
        return this.investorService.investorData!.investor;
    }

    public get portfolioBalance() {
        return this.investorService.portfolioData?.portfolioBalance ?? null;
    }
}