import React from 'react';

interface IClickLinkProps extends React.DetailedHTMLProps<React.HTMLProps<HTMLAnchorElement>, HTMLAnchorElement> {
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    tooltip?: string;
    softDisable?: boolean;
}

export default class ClickLink extends React.Component<IClickLinkProps> {

    constructor(props: IClickLinkProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    private onClick(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();

        this.props.onClick(e);
    }

    public render() {
        if (this.props.softDisable) {
            return <span className='soft-disable' data-tip={this.props.tooltip}>{this.props.children}</span>
        }
        const { onClick, ...rest } = this.props;

        return <a href="/" {...rest} onClick={this.onClick} data-tip={this.props.tooltip}>{this.props.children}</a>
    }
}