import { Neo } from '@singularsystems/neo-react';
import { observer } from 'mobx-react';
import React from 'react';
import { MaintenanceDocumentType } from '../../../Models/Base/Enums/MaintenanceDocumentType';
import InvestorBankDetail from '../../../Models/Investors/InvestorBankDetail';
import { AppService, Types } from '../../../Services/AppService';
import DocumentHandler from '../../../Services/DocumentHandler';

interface IBankAccountDetailsProps {
    bankAccount: InvestorBankDetail;
    readOnly?: boolean;
    registration?: boolean;
    hideRequiredStatement?: boolean;
}

export default class BankAccountDetails extends React.Component<IBankAccountDetailsProps> {

    private appDataCache = AppService.get(Types.Services.AppDataCache);

    public render() {
        const { bankAccount, readOnly, hideRequiredStatement } = this.props;

        return (
            <div>
                <p hidden={readOnly || hideRequiredStatement}> <span className="required-indicator">* </span>indicates a required field</p>
                <Neo.FormGroup
                    bind={bankAccount.meta.bankID}
                    readOnly={readOnly}
                    select={{ itemSource: this.appDataCache.banks, onItemSelected: bank => bankAccount.setBranchCode(bank) }} label={<><span hidden={readOnly} className="required-indicator">* </span>Bank</>} />
                <Neo.FormGroup bind={bankAccount.meta.bankAccountTypeID} readOnly={readOnly} select={{ itemSource: this.appDataCache.bankAccountTypes }} label={<><span hidden={readOnly} className="required-indicator">* </span>Bank account type</>} />
                <Neo.FormGroup bind={bankAccount.meta.accountNo} readOnly={readOnly} label={<><span hidden={readOnly} className="required-indicator">* </span>Account Number</>} />
                <Neo.FormGroup display={bankAccount.meta.branchCode} label={<><span hidden={readOnly} className="required-indicator">* </span>Branch code</>} />
            </div>
        )
    }
}

@observer
export class ThirdPartyBankAccountDetails extends React.Component<IBankAccountDetailsProps> {

    private downloadTask = AppService.get(Types.Neo.TaskRunner);
    private documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient);

    private async downloadDebitOrderAuthorityForm() {
        const response = await this.downloadTask.waitFor(this.documentApiClient.fetchMaintenanceDocument(MaintenanceDocumentType.ThirdPartyDebitOrderAuthority))
        DocumentHandler.convertAndDownloadDocument(response, true);
    }

    public render() {
        const { bankAccount, readOnly, registration } = this.props;

        return (
            <div>
                <p hidden={readOnly || registration}> <span className="required-indicator">* </span>indicates a required field</p>

                <Neo.FormGroup bind={bankAccount.meta.firstName} readOnly={readOnly} label={<><span hidden={readOnly} className="required-indicator">* </span>First name</>} />

                <Neo.FormGroup bind={bankAccount.meta.surname} readOnly={readOnly} label={<><span hidden={readOnly} className="required-indicator">* </span>Surname</>} />

                <BankAccountDetails bankAccount={bankAccount} readOnly={readOnly} hideRequiredStatement={true} />

                <Neo.FormGroup bind={bankAccount.meta.thirdPartyIDNo} readOnly={readOnly} label={<><span hidden={readOnly} className="required-indicator">* </span>ID Number</>} />

                {!readOnly &&
                    <div className="debit-order-authority">
                        <h4 className="mt-4">Debit order authority</h4>

                        <p>You will need to download, complete and upload the form below</p>

                        <Neo.Button task={this.downloadTask} variant="link" className="btn-link-inline" onClick={() => this.downloadDebitOrderAuthorityForm()}>DOWNLOAD DEBIT ORDER AUTHORITY FORM</Neo.Button>

                        <div className="upload-section mt-3">
                            <label><span className="required-indicator">* </span>Upload completed debit order authority form</label>
                            <Neo.FileContext
                                uploadEndpoint={this.documentApiClient.getInvestorDocumentUploadUrl()}
                                afterUpload={docId => bankAccount.documentID = docId}
                                allowedExtensions={this.documentApiClient.allowedInvestorDocumentExtensions}
                                taskRunnerLoadingText={"Uploading Proof of Address"}>
                                <Neo.FileDropArea className={!bankAccount.documentID ? "neo-file-drop" : "neo-file-drop-uploaded"}>
                                    <Neo.FileUploadButton className={!bankAccount.documentID ? "upload-icon" : "upload-completed-icon"}></Neo.FileUploadButton>
                                    {bankAccount.documentID &&
                                        <p className="upload-text">Upload complete</p>
                                    }
                                    <p className="upload-text">Drag your file here or browse to {!bankAccount.documentID ? "upload" : "update"}.</p>
                                    <p className="file-type-text">Jpeg or pdf</p>
                                    <Neo.FileUploadProgress variant={"dark"} type={"animated"} reversed />
                                </Neo.FileDropArea>
                            </Neo.FileContext>
                        </div>
                    </div>}
            </div>
        )
    }
}

export class ReadOnlyBankAccountDetails extends React.Component<IBankAccountDetailsProps> {

    private appDataCache = AppService.get(Types.Services.AppDataCache);

    public render() {
        const { bankAccount } = this.props;

        return (
            <div>
                <Neo.FormGroup style={{marginBottom: '0px'}} display={bankAccount.meta.bankID} select={{ itemSource: this.appDataCache.banks, renderAsText: true }} suppressLabel/>
                <Neo.FormGroup style={{marginBottom: '0px'}} display={bankAccount.meta.bankAccountTypeID} select={{ itemSource: this.appDataCache.bankAccountTypes, renderAsText: true }} suppressLabel/>
                <div>{bankAccount.meta.accountNo.value}</div>
                <div>{bankAccount.meta.branchCode.value}</div>
            </div>
        )
    }
}