import { Views } from '@singularsystems/neo-react';
import { Attributes, List, Model, ModelBase, NeoModel } from '@singularsystems/neo-core';
import { AppService, Types } from '../../../Services/AppService';
import Instrument from '../../../Models/Instruments/Instrument';
import PortfolioCatalogue from '../../../Models/Catalogue/PortfolioCatalogue';
import { Cart } from "../../../Models/Cart/Cart";
import CartDetailLookup from "../../../Models/Cart/CartDetailLookup";
import { NotificationDuration } from "../../../Models/Enums/NotificationDuration";
import { InstrumentProvider } from '../../../Models/Registration/Enums/InstrumentProvider';

@NeoModel
export class FilterCriteria extends ModelBase {

    public instrumentID: number | null = null;

    @Attributes.Display("Region")
    public regionID: number | null = null;

    @Attributes.Display("Asset Class")
    public assetClassID: number | null = null;

    @Attributes.NoTracking()
    public instrumentProviders: Model.ISelectedItem[] = [];

    @Attributes.Display("Provider")
    public instrumentProviderIDs: number[] = [];

    @Attributes.Display("Strategy")
    public strategyID: number | null = null;
}

@NeoModel
export default class ProductsVM extends Views.ViewModelBase {

    constructor(
        taskRunner = AppService.get(Types.Neo.TaskRunner),
        public documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient),
        public appDataCache = AppService.get(Types.Services.AppDataCache),
        public oldSiteIntegration = AppService.get(Types.Services.OldSiteIntegration),
        public investorService = AppService.get(Types.Services.CurrentInvestorService),
        private config = AppService.get(Types.Config),
        public cartApiClient = AppService.get(Types.ApiClients.CartApiClient),
        private notifications = AppService.get(Types.Neo.UI.GlobalNotifications),
    ) {
        super(taskRunner);
    }

    public instruments = new List(Instrument);
    public filterCriteria = new FilterCriteria();
    public portfolioCatalogue: PortfolioCatalogue | null = null;
    public researchPageUrl = this.config.researchPageUrl;
    public cart: Cart | null = new Cart();
    public cartDetailLookups = new List(CartDetailLookup);
    public showPopover = false;
    public totalCartAmount = 0;

    public async initialise() {
        this.instruments = await this.investorService.portfolioData?.fetchInstruments()!
        this.appendSelectAllInstrument();
        this.clearFilterCriteria();

        var cartResult = await this.taskRunner.waitFor(this.cartApiClient.getCartDetailLookup(this.investorService.portfolioData!.portfolio.portfolioID));
        if (cartResult.data) {
            this.cartDetailLookups.set(cartResult.data);
            this.totalCartAmount = this.cartDetailLookups.sum(c => c.amount);
        }
        this.setDefaultInstrumentProvider();
    }

    public setDefaultInstrumentProvider() {
        let defaultInstrumentProvider = this.appDataCache.providers.data.find(c => c.instrumentProviderID === InstrumentProvider.etfSA)

        if (defaultInstrumentProvider) {
            this.filterCriteria.instrumentProviders.push({ id: defaultInstrumentProvider.instrumentProviderID, display: defaultInstrumentProvider.instrumentProviderName });
            this.filterCriteria.instrumentProviderIDs.push(InstrumentProvider.etfSA);
            this.filterCriteria.isSelfDirty = true;
        }
    }

    public appendSelectAllInstrument() {
        let instrument = new Instrument();

        instrument.instrumentID = -1;
        instrument.instrumentName = "All";
        this.instruments.unshift(instrument);
    }

    public get filteredList() {
        let searchListResult = Array.from(this.instruments);

        searchListResult = searchListResult.filter(i =>
            (!this.filterCriteria.instrumentID || (i.instrumentID === this.filterCriteria.instrumentID || this.filterCriteria.instrumentID === -1)) &&
            (!this.filterCriteria.regionID || i.regionID === this.filterCriteria.regionID) &&
            (!this.filterCriteria.assetClassID || i.assetClassID === this.filterCriteria.assetClassID) &&
            (!this.filterCriteria.strategyID || i.strategyID === this.filterCriteria.strategyID) &&
            (this.filterCriteria.instrumentProviders.length === 0 || this.filterCriteria.instrumentProviders.find(c => c.id === i.instrumentProviderID))
        );

        return this.filterCriteria.isSelfDirty ? searchListResult.filter(c => c.instrumentID !== -1) : [];
    }

    public clearFilterCriteria() {
        this.filterCriteria = new FilterCriteria();
        this.filterCriteria.markOld(true);
    }

    public onItemSelect() {
        this.filterCriteria.isSelfDirty = true;

        if (!this.filterCriteria.regionID && !this.filterCriteria.assetClassID && !this.filterCriteria.strategyID && this.filterCriteria.instrumentProviders.length === 0) {
            this.filterCriteria.isSelfDirty = false;
        }
    }

    public async deleteCartItem(instrumentID: number | null) {
        var portfolioId = this.investorService.portfolioData!.portfolio.portfolioID;
        if (instrumentID) {
            await this.taskRunner.run(async () => {
                this.cartApiClient.deleteCartDetail(portfolioId, instrumentID)
                this.notifications.addSuccess("Delete successful", "The cart item was successfully deleted", NotificationDuration.Standard);
            })
        } else {
            await this.taskRunner.run(async () => {
                this.cartApiClient.deleteCartDetail(portfolioId, instrumentID)
                this.notifications.addSuccess("Delete successful", "All cart items was successfully deleted", NotificationDuration.Long);
                this.cartDetailLookups.length = 0;
                this.showPopover = false;
            })
        }
        this.refreshCartInfo()
    }

    private refreshCartInfo() {
        this.totalCartAmount = 0;

        if (this.cartDetailLookups.length > 0) {
            this.totalCartAmount = this.cartDetailLookups.sum(c => c.amount)
        } else {
            this.showPopover = false
        }
    }
}