import { Data, Model, Utils } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import Distribution from '../Models/Reporting/Distribution';
import StatementCriteria from '../Models/Portfolio/StatementCriteria';

export interface IReportingApiClient {

    /**
     * Returns distributions
     */
    getDistributionList(portfolioType: string): AxiosPromise<Array<Model.PlainTrackedObject<Distribution>>>;

    /** 
     * Returns a http response with the distribution reporting content for the given criteria
     */
    getDistributionReport(criteria: Model.PartialPlainNonTrackedObject<StatementCriteria>): AxiosPromise<Blob>;

    // Client only properties / methods
}

@injectable()
export default class ReportingApiClient extends Data.ReadApiClient<Distribution, string> implements IReportingApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/reporting`);
    }

    getDistributionList(portfolioType: string): AxiosPromise<Array<Model.PlainTrackedObject<Distribution>>> {
        return this.axios.get(`${this.apiPath}/distributions/${portfolioType}`);
    }

    getDistributionReport(criteria: Model.PartialPlainNonTrackedObject<StatementCriteria>): AxiosPromise<Blob> {
        return this.axios.get(`${this.apiPath}/distribution-report?${Utils.getQueryString(criteria)}`, {responseType: "blob"});
    }

    // Client only properties / methods
}