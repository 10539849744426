import { Components } from "@singularsystems/neo-core";
import { IErrorData, IErrorDisplay } from "@singularsystems/neo-core/dist/Components/ErrorHandler";
import { AxiosError } from "axios";

export class EtfSAErrorHandler extends Components.ErrorHandler {
    public async parseAxiosError(header: string, body: string, error: AxiosError): Promise<IErrorDisplay> {
        
        body = body.replace("Cannot continue due to the following validation errors:", "");

        const errorDisplay: IErrorDisplay = { header: header, body: body, statusText: error.message, statusCode: error.response?.status } as IErrorDisplay

        if (error.response!.data) {
            
            let rawErrorData = await this.ensureAxiosErrorData(error.response!.data);
            
            if (error.response?.data?.apiValidationMessageList && error.response?.data.apiValidationMessageList.length >= 1) {

                error.response?.data.apiValidationMessageList.forEach((rawErrorData: { primaryProperty: string; detail: string; }) => {
                    if (error.response?.data.apiValidationMessageList.length > 1) {
                        errorDisplay.header = "An error has occurred"
                    } else {
                    errorDisplay.header = rawErrorData.primaryProperty;
                    }
                    errorDisplay.body += this.HTMLtoText(rawErrorData.detail);
                });

            } else {
            
                if (rawErrorData.title) {
                    errorDisplay.body = rawErrorData.title;
                    rawErrorData = rawErrorData.errors;
                }

            if (rawErrorData) {
                if (typeof rawErrorData === "string") {
                    errorDisplay.body = this.HTMLtoText(rawErrorData);
                } else {
                    const errorData = { errors: [], properties: [] } as IErrorData;
                    errorDisplay.data = errorData;

                    if (rawErrorData[""]) {
                        errorData.errors = rawErrorData[""];
                    }
                    Object.keys(rawErrorData).forEach(key => {
                        if (key !== "$id" && key !== "") {
                            const propertyErrors: string[] = rawErrorData[key];
                            if (propertyErrors && propertyErrors instanceof Array) {
                                errorData.properties.push({ key: key, errors: propertyErrors.join(", ") })
                            }
                        }

                    });

                }
            }
        }

        }
        return errorDisplay
    }

    public HTMLtoText = function (str: string) {
        var parser = new DOMParser();
        var doc = parser.parseFromString(str, 'text/html');
        return doc.body.innerText;
    };
}
