import { Attributes, List, Misc, NeoModel, NumberUtils, Validation } from '@singularsystems/neo-core';
import APIModelBase from '../Library/APIModelBase';
import { InvestmentType } from '../Registration/Enums/InvestmentType';
import { PaymentType } from '../Registration/Enums/PaymentType';
import TradeCost, { TradeFees } from './TradeCost';
import { PurchaseInstrumentDetail, SwitchInstrumentDetail } from '../Instruments/InstrumentDetail';
import { RecurringTradeRequestPost } from './RecurringTradeRequest';
import PurchaseTradeRequestBase from '../Library/PurchaseTradeRequestBase';

@NeoModel
export class TradeRequest extends PurchaseTradeRequestBase {

    @Attributes.OnChanged<TradeRequest>(c => c.investmentTypeChange, false)
    public investmentTypeID: InvestmentType = InvestmentType.OnceOffInvestment;

    public paymentMethodID: PaymentType = PaymentType.EFT;

    public debitOrderDay: number = 0;

    public otp: string = "";

    public selectedInstruments = new List(PurchaseInstrumentDetail);

    // Client only properties / methods

    @Attributes.NoTracking()
    public isSourceOfFundsAdditionalInfoRequired: boolean = false;

    @Attributes.NoTracking()
    public initialSourceOfFundsID: number | null =  null;

    @Attributes.NoTracking()
    public initialSourceOfFundsAdditionalInfo: string = "";

    public get totalPurchaseAmount() {
        return this.selectedInstruments.sum(c => c.amount);
    }

    public get estimatedFinalAmount() {
        return this.totalPurchaseAmount - this.tradeFees.totalFees;
    }

    public static validTradeRules: Validation.IRule | null = null;

    @Attributes.NoTracking()
    public get hasTradeCosts() {
        return this.selectedInstruments.sum(c => c.tradeFees.totalFees) > 0;
    }

    @Attributes.NoTracking()
    public tradeCosts = new TradeCost();

    @Attributes.NoTracking()
    public tradeFees = new TradeFees();

    @Attributes.NoTracking()
    public valueAvailable: number = 0;

    @Attributes.NoTracking()
    public instrumentName: string = "";

    @Attributes.NoTracking()
    public hasAcceptedTermsAndConditions: boolean = false;

    @Attributes.NoTracking()
    public hasAcceptedDebitOrderAuthority: boolean = false;

    private investmentTypeChange() {
        if (this.investmentTypeID === InvestmentType.RecurringInvestment) {
            this.paymentMethodID = PaymentType.OnceOffCollection;
            this.sourceOfFundsID = this.initialSourceOfFundsID;
            this.sourceOfFundsAdditionalInfo = this.initialSourceOfFundsAdditionalInfo;
        }
    }

    public toPurchase(): Purchase {
        return Purchase.mapFrom(this);
    }

    public toRecurringTradeRequest(): RecurringTradeRequestPost {
        let recurringTradeRequest = new RecurringTradeRequestPost();

        recurringTradeRequest.portfolioTypeID = this.portfolioTypeID;
        recurringTradeRequest.debitOrderDay = this.debitOrderDay;
        recurringTradeRequest.otp = this.otp;
        recurringTradeRequest.sourceOfFundsID = this.sourceOfFundsID;
        recurringTradeRequest.sourceOfFundsAdditionalInfo = this.sourceOfFundsAdditionalInfo;

        this.selectedInstruments.forEach(c => {
            let instrument = new PurchaseInstrumentDetail();

            instrument.instrumentID = c.instrumentID;
            instrument.amount = c.amount;
            recurringTradeRequest.selectedInstruments.push(instrument);
        });

        return recurringTradeRequest;
    }

    public ensureAtLeastOneInstrument() {
        if (this.selectedInstruments.length === 0) {
            this.selectedInstruments.addNew();
        }
        this.calculateFees();
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        TradeRequest.validTradeRules = rules.add(this.validTradeRules);
    }

    validTradeRules(rule: Validation.IRuleContext): void {
        if (this.selectedInstruments.length === 0) {
            rule.addError("Please choose an instrument to purchase.");
        } else {
            if (this.selectedInstruments.some(c => c.amount <= 0)) {
                rule.addError("The amounts entered are too low. Please enter higher amounts.");
            }
            if ((this.investmentTypeID === InvestmentType.RecurringInvestment || this.paymentMethodID === PaymentType.OnceOffCollection) && this.selectedInstruments.some(c => c.amount > this.tradeCosts.maximumTradeValue)) {
                rule.addError(`The entered amount is above the ${NumberUtils.format(this.tradeCosts.maximumTradeValue, Misc.NumberFormat.CurrencyDecimals)} limit.`);
            }
            if (this.investmentTypeID === InvestmentType.RecurringInvestment && this.debitOrderDay === 0) {
                rule.addError("The debit order day is required.");
            }
        }

        if (!this.sourceOfFundsID || (this.isSourceOfFundsAdditionalInfoRequired && this.sourceOfFundsAdditionalInfo.trim() === "")) {
            rule.addError("Please specify the source of funds and the additional info if applicable.");
        }

        if (!this.isSourceOfFundsAdditionalInfoRequired && this.sourceOfFundsAdditionalInfo.trim() !== "") {
            rule.addError("Please remove the source of funds additional info.");
        }
    }

    calculateFees() {
        let tradeFees = new TradeFees();

        this.selectedInstruments.forEach(e => {
            if (e.instrumentID !== null) {
                tradeFees.brokerage += e.tradeFees.brokerage;
                tradeFees.strateFee += e.tradeFees.strateFee;
                tradeFees.vat += e.tradeFees.vat;
            }
        });

        this.tradeFees = tradeFees;
    }
}

@NeoModel
export class Purchase extends PurchaseTradeRequestBase {

    public paymentMethodID: PaymentType = PaymentType.EFT;

    public otp: string = "";

    public selectedInstruments = new List(PurchaseInstrumentDetail);

    // Client only properties / methods
}

@NeoModel
export class Sale extends APIModelBase {

    public portfolioTypeID: number = 0;

    public instrumentID: number = 0;

    @Attributes.Float()
    public amount: number = 0;

    public otp: string = "";

    // Client only properties / methods

    @Attributes.NoTracking()
    public tradeCosts = new TradeCost();

    @Attributes.NoTracking()
    public tradeFees = new TradeFees();

    @Attributes.NoTracking()
    public valueAvailable: number = 0;

    get isAboveThreshold() : boolean {
        return this.amount >= (this.valueAvailable * 0.9) && this.amount <= this.valueAvailable;
    }

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        rules.failWhen(c => c.amount <= 0, "The withdrawal amount can't be 0 or less. Please enter a higher amount.");
        rules.failWhen(c => c.amount < c.tradeFees.totalFees, "The withdrawal amount is lower than the total fees. Please enter a higher amount.");
        rules.failWhen(c => c.amount > c.valueAvailable, "The withdrawal amount exceeds the amount in this account. Please enter a lower amount.");
    }

    public toString(): string {
        if (this.isNew || !this.otp) {
            return "New sale";
        } else {
            return this.otp;
        }
    }
}

@NeoModel
export class Switch extends APIModelBase {

    public portfolioTypeID: number = 0;

    @Attributes.Integer()
    public instrumentID_From: number = 0;

    public switchToInstrumentDetails = new List(SwitchInstrumentDetail);

    @Attributes.Float()
    public amount: number = 0;

    public otp: string = "";

    // Client only properties / methods

    @Attributes.NoTracking()
    public tradeCosts = new TradeCost();

    @Attributes.NoTracking()
    public tradeFees = new TradeFees();

    @Attributes.NoTracking()
    public valueAvailable: number = 0;

    @Attributes.NoTracking()
    public instrumentName: string = "";

    @Attributes.NoTracking()
    public hasTradeCosts: boolean = true;

    get isAboveThreshold() : boolean {
        return this.amount >= (this.valueAvailable * 0.9) && this.amount <= this.valueAvailable;
    }
    
    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);

        Switch.validTradeRules = rules.add(this.validTradeRules);
    }

    private validTradeRules(rule: Validation.IRuleContext) {
        if (this.switchToInstrumentDetails.length < 1) {
            rule.addError("Please choose an instrument to switch with.");
        } else {
            if (this.amount <= 0) {
                rule.addError("The amounts entered are too low. Please enter higher amounts.");
            } else if (this.amount < this.tradeFees.totalFees) {
                rule.addError("The withdrawal amounts are lower than the total fees. Please enter higher amounts.");
            } else if (this.amount > this.valueAvailable) {
                rule.addError("The total withdrawal amount exceeds the available holdings. Please enter lower amounts.");
            }
        }
    }

    public static validTradeRules: Validation.IRule | null = null;

    public toString(): string {
        if (this.isNew || !this.otp) {
            return "New switch";
        } else {
            return this.otp;
        }
    }

    public setTradeFees() {
        var totalInstrumentToTradeFees = new TradeFees();

        // Calculate fees for each instrument to
        this.switchToInstrumentDetails.forEach(e => {
            totalInstrumentToTradeFees.brokerage = totalInstrumentToTradeFees.brokerage + e.tradeFees.brokerage;
            totalInstrumentToTradeFees.vat = totalInstrumentToTradeFees.vat + e.tradeFees.vat;
            totalInstrumentToTradeFees.strateFee = totalInstrumentToTradeFees.strateFee + e.tradeFees.strateFee;
        });

        // Calculate fees for the instrument from
        this.tradeFees = this.hasTradeCosts ? this.tradeCosts.getFees(this.amount) : new TradeFees();
        this.tradeFees.brokerage = this.tradeFees.brokerage + totalInstrumentToTradeFees.brokerage;
        this.tradeFees.vat = this.tradeFees.vat + totalInstrumentToTradeFees.vat;
        this.tradeFees.strateFee = this.tradeFees.strateFee + totalInstrumentToTradeFees.strateFee;
    }
}