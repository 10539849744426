import { Attributes, NeoModel, Validation } from '@singularsystems/neo-core';
import ApiModelBase from '../ApiModelBase';

@NeoModel
export default class Instrument extends ApiModelBase {

    public instrumentID: number = 0;

    public instrumentCode: string = "";

    public instrumentName: string = "";

    @Attributes.Float()
    public buyPrice: number = 0;

    @Attributes.Float()
    public sellPrice: number = 0;

    public moneyMarketInd: boolean = false;

    public oldInd: boolean = false;

    public isTradeable: boolean = true;

    public instrumentDescription: string = "";

    public instrumentRiskRatingID: number = 0;

    public cashInd: boolean = false;

    public hasTradeCosts: boolean = false;

    public smallImageID: number = 0;

    public largeImageID: number = 0;

    @Attributes.Float()
    public totalExpenseRatio: number = 0;

    public isin: string = "";

    @Attributes.Integer()
    public incomeTreatment: number = 0;

    public instrumentProviderID: number = 0;

    public assetClassID: number = 0;

    public strategyID: number = 0;

    public regionID: number = 0;

    public incomeTreatmentDescription: string = "";

    public investableTypeID: number = 0;

    public investableType: string = "";

    public instrumentProviderImageID: number = 0;

    // Client only properties / methods
    @Attributes.NoTracking()
    public isExpanded: boolean = false;

    protected addBusinessRules(rules: Validation.Rules<this>) {
        super.addBusinessRules(rules);
    }

    public toString(): string {
        if (this.isNew || !this.instrumentCode) {
            return "New instrument";
        } else {
            return this.instrumentCode;
        }
    }
}