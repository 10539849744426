import { Data, Model } from '@singularsystems/neo-core';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';
import { AppService, Types } from '../Services/AppService';
import LoginDetails from '../Models/Security/LoginDetails';
import LoginResult, { TokenInfo } from '../Models/Security/LoginResult';
import Registration from '../Models/Registration/Registration';
import ForgotPassword from '../Models/Security/ForgotPassword';
import UpdatePassword from '../Models/Security/UpdatePassword';
import OTPRegistrationVerification from '../Models/Security/OTPRegistrationVerification';
import APIResponse from '../Models/APIResponse';
import ChangePassword from '../Models/Security/ChangePassword';
import ForgotUsername from '../Models/Security/ForgotUsername';

export interface ISecurityApiClient {

    /**
     * Method used to get authentication token required for any requests that require authentication.
     */
    login(LoginDetails: Model.PartialPlainObject<LoginDetails>): AxiosPromise<Model.PlainObject<LoginResult>>;

    refreshTokenInfo(): AxiosPromise<Model.PlainObject<TokenInfo>>;

    /**
     * Method used to request a Verification email in order to register a new Investor.
     * A new Email will be sent if the registration is still pending confirmation.
     * If the email address links to a registered user, the registration will fail.
     */
    register(RegisterModel: Model.PartialPlainObject<Registration>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /**
     * This method accepts the query string that is included in the URL that is emailed to the user.
     * If the Query String is valid and matches a Registration request that is pending Verification, the request will be Verified.
     * Once this step is complete, the Investor can log in.
     */
    activateRegistrationEmail(QueryString: string): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /**
     * Generates a Forgot password email to the specified email address.
     * The email will contain a URL that will redirect the user to a page where they are able to reset their password.
     */
    forgotPassword(ForgotPasswordModel: Model.PartialPlainObject<ForgotPassword>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /**
     * Method used to reset an Investor's Password.
     * This method requires the Query String that is included in the URL supplied in the Forgot Password Email, as well as the new Password.
     */
    updatePassword(UpdatePasswordModel: Model.PartialPlainObject<UpdatePassword>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    changePassword(passwordDetails: Model.PartialPlainObject<ChangePassword>): AxiosPromise<string>;

    /** 
     * Sends an otp to the logged in investor.
     */
    sendOTP(): AxiosPromise;

    /**
     * Method to verify OTP.
     * This method requires the OTP and the Registration ID.
     */
    checkRegistrationOTPVerification(OTPRegistrationVerification: Model.PartialPlainObject<OTPRegistrationVerification>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /**
     * Sends an otp to a user who is busy registering.
     * This differs from 'send-otp' in that there is no investor record at this point.
     */
    resendOtp(OTPRegistrationVerification: Model.PartialPlainObject<OTPRegistrationVerification>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    /**
    * Generates a Forgot Username email to the email address corresponding with the specified IDNo
    * The email will contain the investor's username..
    */
    forgotUsername(ForgotUsernameModel: Model.PartialPlainObject<ForgotUsername>): AxiosPromise<Model.PlainTrackedObject<APIResponse>>;

    // Client only properties / methods
}

@injectable()
export default class SecurityApiClient extends Data.ApiClientBase implements ISecurityApiClient {

    constructor (config = AppService.get(Types.Config)) {
        super(`${config.apiPath}/security`);
    }

    public login(LoginDetails: Model.PartialPlainObject<LoginDetails>): AxiosPromise<Model.PlainObject<LoginResult>> {
        return this.axios.post(`${this.apiPath}/Login/`, LoginDetails);
    }

    public refreshTokenInfo(): AxiosPromise<Model.PlainObject<TokenInfo>> {
        return this.axios.post(`${this.apiPath}/refresh-token-info`);
    }

    public register(RegisterModel: Model.PartialPlainObject<Registration>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/register`, RegisterModel);
    }

    public activateRegistrationEmail(QueryString: string): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/register/activate-registration-email/${encodeURIComponent(QueryString)}`);
    }

    public forgotPassword(ForgotPasswordModel: Model.PartialPlainObject<ForgotPassword>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/forgot-password`, ForgotPasswordModel);
    }

    public updatePassword(UpdatePasswordModel: Model.PartialPlainObject<UpdatePassword>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/update-password`, UpdatePasswordModel);
    }

    public changePassword(passwordDetails: Model.PartialPlainObject<ChangePassword>): AxiosPromise<string> {
        return this.axios.post(`${this.apiPath}/change-password`, passwordDetails);
    }

    public sendOTP(): AxiosPromise {
        return this.axios.post(`${this.apiPath}/send-otp`);
    }

    public checkRegistrationOTPVerification(OTPRegistrationVerification: Model.PartialPlainObject<OTPRegistrationVerification>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/check-otp`, OTPRegistrationVerification);
    }

    public resendOtp(OTPRegistrationVerification: Model.PartialPlainObject<OTPRegistrationVerification>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/resend-otp`, OTPRegistrationVerification);
    }

    public forgotUsername(ForgotUsernameModel: Model.PartialPlainObject<ForgotUsername>): AxiosPromise<Model.PlainTrackedObject<APIResponse>> {
        return this.axios.post(`${this.apiPath}/forgot-username`, ForgotUsernameModel);
    }

    // Client only properties / methods
}