import React from 'react';
import SwitchVM from './SwitchVM';
import { observer } from 'mobx-react';
import { Neo, NeoGrid } from "@singularsystems/neo-react";
import NumberUtils from "@singularsystems/neo-core/dist/NumberUtils";
import { Misc } from "@singularsystems/neo-core";
import TradeCostDetails from "../Components/TradeCostDetails";
import { Switch } from "../../../Models/Base/TradeRequest";
import { AppService, Types } from "../../../Services/AppService";
import { ScreenSize } from '../../../Services/AppLayout';

interface ISwitchProps {
    viewModel: SwitchVM;
}

@observer
export default class SwitchComponent extends React.Component<ISwitchProps> {
    private layoutService = AppService.get(Types.Services.AppLayout);


    public render() {
        const viewModel = this.props.viewModel;
        const validation = viewModel.currentSwitch.validator.getRuleResult(Switch.validTradeRules);
        const amountExceedsAvailable = viewModel.currentSwitch.valueAvailable < viewModel.currentSwitch.amount;

        return (
            <div className="transaction-page">

                <h3 className="page-header">{"Switching from " + viewModel.currentSwitch.instrumentName + " to:"}</h3>

                <div className="page-container mt-4">

                    <div className="row justify-content-between">

                        <div className="col-md-9">

                            <Neo.Form model={viewModel.currentSwitch} onSubmit={() => viewModel.showOtp()} className="p-2">

                                <div className="mb-3">
                                    <NeoGrid.Grid items={viewModel.currentSwitch.switchToInstrumentDetails}
                                        addButton={{
                                            text: "Add Instrument",
                                            size: "sm",
                                            disabled: amountExceedsAvailable,
                                            tooltip: amountExceedsAvailable ? "The total withdrawal amount exceeds the available holdings. Please enter lower amounts." : ""
                                        }}
                                        className="mb-1"
                                        footerButtons={() =>
                                            <div className="link-medium pt-2">
                                                <Neo.Link onClick={() => viewModel.switchPreferredProportions()}>{viewModel.isPercentage ? "Switch To % Value" : "Switch To Rand Value (R)"}</Neo.Link>
                                            </div>
                                        }>
                                        {(item, meta) => (
                                            <NeoGrid.Row>
                                                <NeoGrid.Column bindContext={item.meta.instrumentID} className="column-width-30" label={"Instrument To"} alignment={"left"}>
                                                    <Neo.AutoCompleteDropDown
                                                        bind={item.meta.instrumentID}
                                                        displayMember="instrumentName"
                                                        valueMember="instrumentID"
                                                        onItemSelected={() => viewModel.onInstrumentSelected(item)}
                                                        items={viewModel.toInstruments}
                                                    />
                                                </NeoGrid.Column>
                                                <NeoGrid.Column
                                                    bind={meta.percentage}
                                                    numProps={{ format: Misc.NumberFormat.PercentDecimals }}
                                                    width={this.layoutService.currentScreenSize >= ScreenSize.Medium ? "20%" : "30%"}
                                                    label="%"
                                                    hidden={viewModel.isPercentage}
                                                    disabled={viewModel.checkInstrumentInd(item, viewModel.isPercentage)}
                                                    onChange={() => viewModel.recalculateSwitch(item)}
                                                />
                                                <NeoGrid.Column
                                                    bind={meta.amount}
                                                    numProps={{ format: Misc.NumberFormat.CurrencyDecimals, currencySymbol: "R" }}
                                                    width={this.layoutService.currentScreenSize >= ScreenSize.Medium ? "20%" : "30%"}
                                                    label="Amount"
                                                    hidden={!viewModel.isPercentage && this.layoutService.currentScreenSize <= ScreenSize.Medium}
                                                    disabled={viewModel.checkInstrumentInd(item, !viewModel.isPercentage)}
                                                    onChange={() => viewModel.recalculateSwitch(item)}
                                                />
                                                <NeoGrid.Column
                                                    display={meta.estimatedUnits}
                                                    numProps={{ format: Misc.NumberFormat.NoDecimals, zeroText: "-" }}
                                                    width={"15%"}
                                                    label="Est. Units" 
                                                    hideBelow='md'/>
                                                <NeoGrid.ButtonColumn showDelete onClick={() => viewModel.recalculateSwitch(item)} buttonSize="sm" />
                                            </NeoGrid.Row>
                                        )}
                                    </NeoGrid.Grid>
                                </div>

                                <div className="mb-3 ml-4">
                                {viewModel.currentSwitch.isAboveThreshold && <div className="error-text pb-2">Please note a switch of over 90% of your holdings will lead to a 100% switch</div>}
                                    <div hidden={!viewModel.hasTradeCosts}>
                                        <h4>Total Costs for this Transaction</h4>
                                        <TradeCostDetails tradeFees={viewModel.currentSwitch.tradeFees} />
                                    </div>

                                    <hr />

                                    <div className="row-breakdown" style={{ paddingBottom: "Inherit" }}>
                                        <div className="col-8">Estimated Final Amount:</div>
                                        <div className="col-4 text-right">{NumberUtils.format(viewModel.currentSwitch.amount - viewModel.currentSwitch.tradeFees.totalFees, Misc.NumberFormat.CurrencyDecimals, "R")}</div>
                                    </div>
                                </div>

                                <div className="text-right">
                                    <Neo.Button variant="primary" isSubmit disabled={!viewModel.currentSwitch.isValid}
                                        tooltip={validation?.getDisplayInfo(true).displayText}>Confirm</Neo.Button>
                                </div>

                            </Neo.Form>
                            {this.layoutService.currentScreenSize < ScreenSize.Medium && <div className="content-box">
                                <label>Available Holdings</label>
                                <div>{NumberUtils.format(viewModel.currentSwitch.valueAvailable ?? 0, Misc.NumberFormat.CurrencyDecimals, "R")}</div>
                                <hr />
                                {!viewModel.isPercentage &&
                                    <div>
                                        <label>Total Switch %</label>
                                        <div className={amountExceedsAvailable ? "error-text" : ""}>
                                            {NumberUtils.format(viewModel.totalPercentage ?? 0, Misc.NumberFormat.PercentDecimals, "%")}</div>
                                        <hr />
                                    </div>}
                                <label>Total Switch Amount</label>
                                <div className={amountExceedsAvailable ? "error-text" : ""}>
                                    {NumberUtils.format(viewModel.currentSwitch.amount ?? 0, Misc.NumberFormat.CurrencyDecimals, "R")}</div>
                            </div>}

                        </div>

                        <div className="order-first order-md-2 col-md-3 mb-3 total-amounts">
                            {this.layoutService.currentScreenSize >= ScreenSize.Medium && <div className="content-box">
                                <label>Available Holdings</label>
                                <div>{NumberUtils.format(viewModel.currentSwitch.valueAvailable ?? 0, Misc.NumberFormat.CurrencyDecimals, "R")}</div>
                                <hr />
                                {!viewModel.isPercentage &&
                                    <div>
                                        <label>Total Switch %</label>
                                        <div className={amountExceedsAvailable ? "error-text" : ""}>
                                            {NumberUtils.format(viewModel.totalPercentage ?? 0, Misc.NumberFormat.PercentDecimals, "%")}</div>
                                        <hr />
                                    </div>}
                                <label>Total Switch Amount</label>
                                <div className={amountExceedsAvailable ? "error-text" : ""}>
                                    {NumberUtils.format(viewModel.currentSwitch.amount ?? 0, Misc.NumberFormat.CurrencyDecimals, "R")}</div>
                            </div>}
                        </div>
                    </div>
                </div>

                <Neo.Modal
                    title={"Switch Submitted"}
                    show={viewModel.showSuccessModal}
                    size="lg"
                    onClose={() => viewModel.navigateToDashboard(true)}
                    closeButton={false}
                    acceptButton={{ text: "GO TO MY DASHBOARD", onClick: () => viewModel.navigateToDashboard(true) }}>
                    <div>
                        Please note switches can take 5-7 days.
                        <br />
                        Contact us at <a href="mailto:hub@etfsa.co.za">hub@etfsa.co.za</a> for any queries.
                    </div>
                </Neo.Modal>

            </div>
        );
    }
}