import { FileUtils } from '@singularsystems/neo-core';
import { Neo } from '@singularsystems/neo-react';
import React from 'react';
import ClickLink from '../../../App/Components/ClickLink';
import StatusImage from '../../../App/Components/StatusImage';
import { DocumentType } from '../../../Models/Enums/DocumentType';
import UpdateAddressCommand from '../../../Models/Investors/UpdateAddressCommand';
import { AppService, Types } from '../../../Services/AppService';
import { AddressDetails, UpdatableAddressDetails } from '../../Investor/Components/AddressDetails';
import AccountInformationVM from '../AccountInformationVM';

interface IAccountAddressInfoProps {
    viewModel: AccountInformationVM;
}

export default class AccountAddressInfo extends React.Component<IAccountAddressInfoProps> {

    private documentApiClient = AppService.get(Types.ApiClients.DocumentApiClient);

    private downloadTask = AppService.get(Types.Neo.TaskRunner);

    private async downloadProofOfAddress() {
        const response = await this.downloadTask.waitFor(this.documentApiClient.getInvestorDocument(DocumentType.ProofOfAddress));
        FileUtils.showSaveFile(response);
    }

    public render() {
        const viewModel = this.props.viewModel,
            investor = viewModel.investor!;

        return (
            <div>
                <div className="info-panel-status-header">
                    <h4>Proof of Residence</h4>
                    <StatusImage status={investor.investorFICAStatusAddress} />
                </div>
                
                <div className="mt-3">
                    <AddressDetails addressDetail={investor.addressDetails} readOnly maxColumns={1} />
                </div>

                <div>
                    <ClickLink onClick={e => this.downloadProofOfAddress()}>Download proof <i className="fa fa-download" /></ClickLink>
                </div>
                
                <Neo.Button className="mt-3" onClick={() => { viewModel.beginEditAddress(); }}>Change</Neo.Button>
            </div>
        )
    }
}

export class AccountAddressEditModal extends React.Component<IAccountAddressInfoProps> {

    public render() {
        const viewModel = this.props.viewModel;

        return (
            <Neo.Modal title="Change / Edit Address" bindModel={viewModel.meta.editAddress}
                size="lg"
                closeButton={false}
                acceptButton={{ text: "Submit", onClick: () => viewModel.saveAddress()}}>
                {(command: UpdateAddressCommand) => (
                    <UpdatableAddressDetails command={command} />
                )}
            </Neo.Modal>)
    }
}