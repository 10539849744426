import { Utils } from '@singularsystems/neo-core';
import React from 'react';
import PopoutArrow from "../../assets/img/arrow.svg";

export interface IPopoverProps {
    anchorElement: HTMLElement;
    alignment: "center" | "right";
    onHide: (isInPopover: boolean) => void;
    yOffset: number;
    className?: string;
    maxElementNesting?: number;
}

export default class Popover extends React.Component<IPopoverProps> {

    constructor(props: IPopoverProps) {
        super(props);

        this.onMouseDown = this.onMouseDown.bind(this);
    }

    private popoverRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        window.addEventListener("mousedown", this.onMouseDown);

        const anchor = this.props.anchorElement;

        if (this.popoverRef.current) {

            const anchorRect = anchor.getBoundingClientRect();
            const thisRect = this.popoverRef.current.getBoundingClientRect();

            const left = this.props.alignment === "center" ?
                anchor.offsetLeft + (anchorRect.width / 2) - (thisRect.width / 2) :
                anchor.offsetLeft + anchorRect.width - thisRect.width;
            this.popoverRef.current.style.left = left + "px";

            const top = anchor.offsetTop + anchorRect.height + this.props.yOffset + 10; // Add arrow height
            this.popoverRef.current.style.top = top + "px";
        }

    }

    componentWillUnmount() {
        window.removeEventListener("mousedown", this.onMouseDown);
    }

    private onMouseDown(e: MouseEvent) {
        let target = e.target as HTMLElement | null;
        let level = 0;
        do {
            if (target === this.props.anchorElement || target === this.popoverRef.current) {
                return;
            }
            target = target!.parentElement;
        } while (target && ++level < (this.props.maxElementNesting ? this.props.maxElementNesting : 5))

        this.props.onHide(false);
    }

    public render() {

        return (
            <div ref={this.popoverRef} className={Utils.joinWithSpaces("popover-container", this.props.className)} onClick={(e) => this.props.onHide(true)}>
                <img alt="" src={PopoutArrow} className={"popover-arrow arrow-" + this.props.alignment} />
                <div className="popover-body">
                    {this.props.children}
                </div>
            </div>
        )
    }
}